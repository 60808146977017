import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Event from '../components/Event';
import BannerCarousel from '../components/BannerCarousel';
import LoadingSpinner from '../components/LoadingSpinner';
import { assets, environment } from '../data';
import SlideShow from '../components/SlideShow';
import { useMeta } from '../MetaContext';

export default function Events() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribeLoading, setIsSubscribeLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [banners, setBanners] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [subscriberEmail, setSubscriberEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();
  const { setMeta } = useMeta();
  useEffect(() => {
    let subdomain = window.location.hostname.split('.')[0];
    if (
      subdomain === 'oneticket' ||
      subdomain === 'localhost' ||
      subdomain === 'www'
    ) {
      subdomain = '';
    }
    //setSubDomain(subdomain);
    console.log('Subdomain:', subdomain);
    if (subdomain == 'dev-organization') {
      getData('');
    } else {
      getData(subdomain);
    }
    subscribeInitPopup();
    getPastData();
  }, []);

  function subscribeInitPopup() {
    const isSubscribe = localStorage.getItem('subscribePopup');
    if (isSubscribe) {
      setIsPopupOpen(false);
    } else {
      setIsPopupOpen(true);
    }
  }
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setSubscriberEmail(email);

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  function getData(subdomain) {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/events/event-all/?limit=10000&page=1&sub_domain=${subdomain}`,
      )
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data.data);
        if (data?.status === 223) {
          navigate('/notfound');
        }
        if (data?.status === 100) {
          setEvents(data.data.data);

          // let b = [];
          // data.data.data.forEach((e) => {
          //   if (e.event_banner) {
          //     b.push(e.event_banner);
          //   }
          // });
          // console.log(b);
          // setBanners(b);
        } else {
          console.error(data.message || 'Getting events failed!');
          toast.error('Getting events failed!', { id: 'getEvents' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEvents' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function saveSubscriber() {
    setIsSubscribeLoading(true);
    let body = {
      customer_email: subscriberEmail,
    };

    axios
      .post(`${environment.BASE_URL2}/events/event-subscribe/`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // console.log(res);
        const data = res?.data;
        console.log(data.data);
        if (data?.status === 223) {
          navigate('/notfound');
        }
        if (data?.status === 100) {
          setSubscriberEmail('');
          setIsPopupOpen(false);
          toast.success('Subscribe success');
          localStorage.setItem('subscribePopup', true);
        } else {
          toast.error('Subscribe failed!', { id: 'saveSubscribe' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'saveSubscribe' });
      })
      .finally(() => {
        setIsSubscribeLoading(false);
      });
  }

  function getPastData() {
    setIsLoading(true);

    axios
      .get(
        `${environment.BASE_URL2}/events/event-all/?limit=10000&page=1&type=expired`,
      )
      .then((res) => {
        console.log(res);
        const data = res?.data;
        console.log(data);
        if (data?.status === 223) {
          navigate('/notfound');
        }
        if (data?.status === 100) {
          setPastEvents(data.data.data);

          let b = [];
          data.data.data.forEach((e) => {
            if (e.event_banner) {
              b.push(e.event_banner);
            }
          });
          console.log(b);
          setBanners(b);
        } else {
          console.error(data.message || 'Getting past events failed!');
          toast.error('Getting past events failed!', { id: 'getPastEvents' });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error('Oh! Something went wrong', { id: 'getEvents' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // function generateEvents() {
  //   let ev = [];

  //   for (let i = 0; i < 20; i++) {
  //     ev = ev.concat(events);
  //   }
  //   return ev;
  // }

  return (
    <>
      <div className="cover d-none d-sm-block">
        <img src={assets.polygon_icon} alt="" className="i1" />
        <img src={assets.polygon_icon} alt="" className="i2" />
        <img src={assets.polygon_icon} alt="" className="i3" />
        <img src={assets.polygon_filled_icon} alt="" className="i4" />
        {/* <img src={assets.group_icon} alt="" className="i5 d-none d-lg-block" /> */}
      </div>

      <div className="container">
        <div className="events">
          <div className="events-1 row">
            <div className="events-1-1 col-12 col-lg-6">
              <p className="heading">
                Convenient and Secure Ticket Reservations <br /> At your{' '}
                <span className="colured">fingertips</span>
              </p>

              <br />
              <br />

              {/* <p className="mt-4">Tickets at your fingertips,</p> */}

              <div className="search d-flex align-items-center mt-3 mt-md-5">
                <div className="input col-8 me-3">
                  <img src={assets.search_icon} alt="search icon" />
                  <input
                    type="text"
                    placeholder="Search event"
                    className="form-control"
                  />
                </div>

                <div className="col-4">
                  <button className="primary-btn search-btn">Search</button>
                </div>
              </div>
            </div>

            <div className="events-1-2 d-none d-lg-block col-lg-6">
              <BannerCarousel banners={banners} />
              {/* <div className="banner-carousel">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${environment.aws + '/' + banners[index]})`,
                  }}></div>
                <img src={assets.group_icon} alt="" className="i5 d-none d-lg-block" />
              </div> */}
            </div>
          </div>

          <div className="events-2">
            <div className="events-2-1 d-flex align-items-center justify-content-between mb-3">
              <h6>Events</h6>

              {/* <p>DD : MM :YYYY &nbsp; - &nbsp; DD : MM :YYYY</p> */}
            </div>

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {events.length > 0 ? (
                  <div className="list">
                    {events.map((e, i) => (
                      <Event key={i} event={e} />
                    ))}
                  </div>
                ) : (
                  <p className="my-5">No available events!</p>
                )}
              </>
            )}
          </div>
          <div className="events-2">
            <div className="events-2-1 d-flex align-items-center justify-content-between mb-5">
              <h6>Past events</h6>

              {/* <p>DD : MM :YYYY &nbsp; - &nbsp; DD : MM :YYYY</p> */}
            </div>

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {pastEvents.length > 0 ? (
                  <div className="list">
                    {pastEvents.map((e, i) => (
                      <Event key={i} event={e} disable={true} />
                    ))}
                  </div>
                ) : (
                  <p className="my-5">No available events!</p>
                )}
              </>
            )}
          </div>
          <div className="events-1 row mt-5">
            <SlideShow />

            <div className="events-1-2 d-none d-lg-block col-lg-6"></div>
          </div>
        </div>
      </div>
      {isPopupOpen && <div className="backdrop"></div>}
      {isPopupOpen && (
        <div className={`popup-main ${isPopupOpen ? 'popup-visible' : ''}`}>
          <div className="close-icon-div">
            <img
              src={assets.close_popup}
              alt="close offcanvas icon"
              loading="lazy"
              width={24}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('subscribePopup', true);
                setIsPopupOpen(false);
              }}
            />
          </div>
          <div className="people-img-div">
            <img
              src={assets.popup_people}
              alt="close offcanvas icon"
              loading="lazy"
              className="people-img-div"
            />
          </div>
          <div className="popup-main-topic">
            Subscribe Oneticket for notify future events.
          </div>
          <div className="popup-text-des">
            Stay in the loop and never miss out on your favorite concerts,
            shows, and performances. Sign up now to get exclusive updates
            delivered straight to your inbox.
          </div>
          <div className="email-add d-flex align-items-center mt-3 mt-md-5">
            <div className="input col-8 me-3">
              {/* <img src={assets.search_icon} alt="search icon" /> */}
              <input
                type="text"
                placeholder="Write your email address"
                className="form-control"
                value={subscriberEmail}
                onChange={handleEmailChange}
              />
              {emailError && <div className="error-message">{emailError}</div>}
            </div>

            <div className="col-4">
              <button
                className="primary-btn search-btn"
                onClick={saveSubscriber}
              >
                {isSubscribeLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  'Subscribe'
                )}
              </button>
            </div>
          </div>
          <div className="whatsapp-div">
            <img
              src={assets.whats_app}
              alt="close offcanvas icon"
              loading="lazy"
              width={21}
            />
            <div className="whatsapp-text">
              <a
                href="https://whatsapp.com/channel/0029VaZFgan7YSczRoYSF90y"
                target="blank"
                style={{ textDecoration: 'none' }}
              >
                Join with WhatsApp Channel
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
